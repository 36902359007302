<template>
	<div class="person-info">
		
		<div class="form">
			<el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="100px" class="demo-ruleForm">
				<el-form-item :label="$t('realname')" prop="name">
					<el-input v-model="ruleForm.name" :placeholder="$t('enterrealname')"></el-input>
			 </el-form-item>
				<el-form-item :label="$t('regions')"  prop="countryArea">
					<el-input v-model="ruleForm.countryArea" :placeholder="$t('enterregions')"></el-input>
				</el-form-item>
				<el-form-item :label="$t('documentnum')"  prop="idCardNum">
					<el-input v-model="ruleForm.idCardNum" :placeholder="$t('enterdocumentnum')"></el-input>
				</el-form-item>
				<el-form-item :label="$t('documentphoto')"  prop="idCardImg">
					<el-upload
					    class="avatar-uploader"
					    action="https://jsonplaceholder.typicode.com/posts/"
					    :show-file-list="false"
					    :on-success="handleAvatarSuccess"
					    :before-upload="beforeAvatarUpload">
					  <img v-if="ruleForm.idCardImg" :src="ruleForm.idCardImg" class="avatar">
					  <div class="el-upload__tip">{{$t('enterdocumentphoto')}}</div>
					</el-upload>
				</el-form-item>
				<el-form-item class="button-finish">
					<el-button @click="submit" type="primary">{{$t('submitreport')}}</el-button>
				</el-form-item>
			</el-form>
		</div>
	</div>
</template>

<script>
	export default {
		props: {},
		computed: {},
		components: {},
		data() {
			return {
				idCardImg: '',
				ruleForm: {
					name: '',
					countryArea: '',
					idCardNum: '',
					idCardImg: ''
				},
				rules: {},
			}
		},
		methods: {
			handleAvatarSuccess(){
				
			},
			beforeAvatarUpload(){
				
			},
			// 提交审核
			submit(){
				
				this.$router.push({
					path: '/personalCenter/accountManagement/authenticationSucceeded'
				})
			}
		}
	}
</script>

<style lang="less" scoped>
	.person-info {
		width: 100%;
		height: 650px;
		background: @recommendMusicianListBGColor;
		border-radius: 6px;
		backdrop-filter: blur(50px);
		margin-top: 16px;
		display: flex;
		flex-direction: column;
		align-items: center;
		// justify-content: center;
		padding-top: 30px;
		.avatar{
			// width: 100%;
			margin-bottom: 40px;
			display: flex;
			flex-direction: column;
			// justify-content: center;
			align-items: center;
			.avatar-img {
				width: 120px;
				height: 120px;
			}
			.avatar-name{
				height: 22px;
				font-size: 16px;
				font-weight: 500;
				color: @recommendMusicianListTextColor;
				margin-top: 16px;
			}
		}
		.form {
			* {
				::-webkit-scrollbar {
					/*隐藏滚轮*/
					display: none;
				}
			}
			.demo-ruleForm {
				::v-deep .el-input {
					width: 400px;
					background: @recommendMusicianListBGColor;
					border: none;
				}

				::v-deep .el-form-item__label {
					color: @recommendMusicianListTextColor;
				}

				::v-deep .el-input__inner {
					width: 400px;
					background: rgba(34, 36, 38, 0.0500);
					border: none;
					&:hover {
					  border: 1px solid @popupCancelTextColor;
					}
				}

				::v-deep .el-form-item__label {
					color: @recommendMusicianListTextColor;
				}

				::v-deep .el-button--primary {
					width: 86px;
					height: 38px;
					background: #3370FF;
					border-radius: 4px;
					border: none;
					display: flex;
					justify-content: center;
					align-items: center;
					font-size: 14px;
					color: #FFFFFF;
				}

				::v-deep .el-input-group__append,
				.el-input-group__prepend {
					background: rgba(0, 0, 0, 0);
					border: none;
				}

				::v-deep .el-textarea {
					width: 400px !important;
					background: @recommendMusicianListBGColor;
				}

				::v-deep .el-textarea__inner {
					width: 400px !important;
					height: 128px;
					background: rgba(34, 36, 38, 0.0500);
					border: none;
					resize: none;
					&:hover {
					  border: 1px solid @popupCancelTextColor;
					}
				}

				::v-deep .el-input__count {
					background: rgba(0, 0, 0, 0);
				}

				.upload{
					.upload-title{
						line-height: 36px;
					}
				}
				// 上传样式
				::v-deep .avatar-uploader {
				  display: flex;
				  align-items: center;
				  justify-content: center;
				  width: 170px;
				  height: 100px;
				  background: @personInfoFormColor;
				}
				
				::v-deep .avatar-uploader .el-upload {
					width: 170px;
					height: 100px;
					display: flex;
					align-items: center;
					justify-content: center;
				  border: 1px dashed @headerSelectTextColor;
				  border-radius: 6px;
				  cursor: pointer;
				  position: relative;
				  overflow: hidden;
				}
				
				::v-deep .avatar-uploader .el-upload:hover {
				  border-color: #409EFF;
				}
				
				.avatar-uploader-icon {
				  font-size: 28px;
				  color: #fff;
				  width: 148px;
				  height: 60px;
				  line-height: 80px;
				  text-align: center;
				
				  &:hover {
				    color: #409EFF;
				  }
				}
				
				.avatar {
				  width: 148px;
				  height: 60px;
				  display: block;
				}
				
				::v-deep .el-upload__tip {
				  // padding-top: 64px;
				  margin-left: 8px;
				  line-height: 16px;
				  color: @headerSelectTextColor;
				}

				.button-finish {
					display: flex;
					// justify-content: center;
					padding-left: 71px;

					::v-deep .el-button {
						width: 140px;
						height: 38px;
						background: #3370FF;
						border-radius: 6px;
					}

				}

			}
		}
	}
</style>
